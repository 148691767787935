/* eslint-disable @angular-eslint/component-selector */
import { AfterContentInit, Component, EventEmitter, Input, Output, SimpleChanges } from '@angular/core';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { TranslateService } from '@ngx-translate/core';
import { StoreSettingsEmailsModel } from '../../models/store-setting.email.model';
import { EmailSaveEvent } from '../email-input/email-input.component';
import { Language } from '../../../configs/constant.config';
import { SecurityService } from '../../services/security.service';
import { MatRadioChange } from '@angular/material/radio';
import { PreferencesService } from '../../services/preferences.service';
import { BehaviorSubject, Observable } from 'rxjs';
import { InvitationMailDatabaseComponent, InvitationMailTemplate } from '../../models/invitation-mail-template.model';
import { MatDialog } from '@angular/material/dialog';
import { InvitationLanguageChangeDialogComponent } from '../../dialogs/invitation-language-change/invitation-language-change.dialog.component';
import { map } from 'rxjs/operators';
import { ApplicationInsightsService } from '../../services/applicationInsights.Service';
import { OAuthService } from 'angular-oauth2-oidc';
import { AdminAppEnvironment as environment } from 'visenvironment';
import { AdminErrorHandler } from '../../../handler/adminErrorHandler.service';
import { MatSelectChange } from '@angular/material/select';
import { FileUploadObjectModel, UploadFileType } from '../../models/store-setting.object.model';
import { SettingsFlagType } from '../../models/store-setting.submitflag.model';
import { InvitationMailPreviewDialogComponent } from '../../dialogs/invitation-mail-preview-dialog/invitation-mail-preview-dialog.component';
import { LanguageService } from '../../services/language.service';

@Component({
  selector: 'vcld-adm-store-settings',
  templateUrl: './store-settings.component.html',
  styleUrls: ['./store-settings.component.scss'],
})
export class StoreSettingsComponent implements AfterContentInit {

  @Input()
  index: number;

  @Input()
  public email: StoreSettingsEmailsModel;

  @Input()
  public isLoading: boolean;

  @Input()
  public isSingleOptician: boolean;

  @Output()
  public onSave: EventEmitter<StoreSettingsEmailsModel> = new EventEmitter();

  public _invitationLanguagePreviousValue:string;
  public templateLanguages: Language[];

  public get availableLanguages() {
    return this.templateLanguages;
  }

  public _selectedLanguage: string = "en-US";

  public _selectednotificationLanguage: string = "en-US";

  public isNotificationLanguageChanged:boolean = false;

  public isInvitationLanguageChanged:boolean = false;

  public _internalEmails = [];

  public opticianCustomName: string = 'test';

  private readonly EMAIL_REGEX: RegExp = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/i;

  public canEdit: boolean = false;

  public isModified: boolean = false;

  public isInvitataionMailSubmitDisabled: boolean = true;

  public isChanged: boolean = false;

  public isFeatureFlagChecked: boolean = false;

  public onlyMail$: BehaviorSubject<boolean> = new BehaviorSubject(true);

  public defaultInvitation: boolean = true;

  public invitationTemplate$: BehaviorSubject<InvitationMailTemplate> = new BehaviorSubject(null);

  public customBannerEnabled: boolean = false;

  public updatedEmailModel: StoreSettingsEmailsModel;

  public defaultBanner$;

  public invitationDatabaseComponents$: Observable<InvitationMailDatabaseComponent[]> = this.pref.invitationDatabaseComponents$;

  public invitationTemplateComponents$ = this.invitationTemplate$.pipe(
    map(t => {
      if (t) {
        return t.components;
      }
      return [];
    })
  );

  public templateLoading: boolean = false;

  public bannerExists$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public savableBannerOptions$: BehaviorSubject<boolean> = new BehaviorSubject(false);

  constructor(
    public translate: TranslateService,
    private security: SecurityService,
    private pref: PreferencesService,
    private dialog: MatDialog,
    public appInsight: ApplicationInsightsService,
    private auth: OAuthService,
    public errorHandler: AdminErrorHandler,
    private language: LanguageService
  ) { }

  async ngOnInit(): Promise<void> {
    this.canEdit = await this.security.canSeeAsync('admin_edit_ecpsettings', false);
    this.pref.setStoreSettingPageModificationFlag(SettingsFlagType.ModifiedFlag,false);

    this.language.availableLanguages$.subscribe((data)=>
    {
        this.templateLanguages = data;
    });
  }

  async ngAfterContentInit(): Promise<void> {
    if (this.email) {
      this.opticianCustomName = this.email.opticianName;
      this._internalEmails = [...this.email.emails];
      if (this._internalEmails?.length > 1) {
        this.onlyMail$.next(false);
      }
      if (this.email.invitationLanguage) this._selectedLanguage = this.email.invitationLanguage;

      if (this.email.notificationLanguage) this._selectednotificationLanguage = this.email.notificationLanguage;

      if (this.email.customBannerEnabled != undefined) this.customBannerEnabled = this.email.customBannerEnabled;

      if (this.email.customInvitationEnabled) {

        this.templateLoading = true;
        this.defaultInvitation = !this.email.customInvitationEnabled;
        this.invitationTemplate$.next(await this.pref.getNewInvitationMailTemplate(this.email.customInvitationEnabled ? this.email.opticianId : 'default', this._selectedLanguage));
        this.templateLoading = false;
      }
      this.updatedEmailModel = JSON.parse(JSON.stringify(this.email));
    }

    this.loadDefaultBanner();
  }

  public addEmail(): void {
    const placeholder = this.translate.instant('pages.store-settings.mail_placeholder');
    this._internalEmails.push(placeholder);
  }

  public removeEmail(index: number) {
    this._internalEmails.splice(index, 1);
    this.saveEmail();
  }
  public internalEmailValueChange(e: EmailSaveEvent) {
    const testResult: boolean = this.EMAIL_REGEX.test(e.email);
    if (testResult) {
      this.updatedEmailModel.emails[e.index] = e.email;
      this.pref.updateEmailDetails(this.index, this.updatedEmailModel);
    }
  }
  public onCheckboxChanged(e: MatCheckboxChange): void {
    this.email.invitationEnabled = e.checked;
    this.isInvitataionMailSubmitDisabled = false;
    this.pref.setStoreSettingPageModificationFlag(SettingsFlagType.InvitationMailChecked,true);
    this.updatedEmailModel.invitationEnabled = e.checked;
    this.pref.updateEmailDetails(this.index, this.updatedEmailModel);

  }
  public onLogoFileInputChange(logoUploadfile: File) {
    const logoUploadObject: FileUploadObjectModel = new FileUploadObjectModel();
    logoUploadObject.uploadfile = logoUploadfile;
    logoUploadObject.opticianId = this.email.opticianId;
    logoUploadObject.uploadFileType = UploadFileType.LogoInput;
    this.pref.updateFileUploadedDetails(this.index, logoUploadObject);
    this.pref.setStoreSettingPageModificationFlag(SettingsFlagType.LogoUpdated,logoUploadfile != null);
  }
  public onBannerFileInputChange(bannerUploadfile: File) {
    const bannerUploadObject: FileUploadObjectModel = new FileUploadObjectModel();
    bannerUploadObject.uploadfile = bannerUploadfile;
    bannerUploadObject.opticianId = this.email.opticianId;
    bannerUploadObject.uploadFileType = UploadFileType.BannerInput;
    this.pref.updateFileUploadedDetails(this.index, bannerUploadObject);
  }
  public onPricingChanged(enabled: boolean): void {
    this.email.featureFlags = { ...this.email.featureFlags, pricingEnabled: enabled };
    this.updatedEmailModel.featureFlags = { ...this.updatedEmailModel.featureFlags, pricingEnabled: enabled };
    this.pref.updateEmailDetails(this.index, this.updatedEmailModel);
    this.isFeatureFlagChecked = true;
    this.pref.setStoreSettingPageModificationFlag(SettingsFlagType.PriceFlagEnabled,true);
  }

  public onMaterialChanged(enabled: boolean): void {
    this.email.featureFlags = { ...this.email.featureFlags, materialEnabled: enabled };
    this.updatedEmailModel.featureFlags = { ...this.updatedEmailModel.featureFlags, materialEnabled: enabled };
    this.pref.updateEmailDetails(this.index, this.updatedEmailModel);
    this.isFeatureFlagChecked = true;
    this.pref.setStoreSettingPageModificationFlag(SettingsFlagType.MaterialFlagEnabled,true);
  }

  public onColorChanged(enabled: boolean): void {
    this.email.featureFlags = { ...this.email.featureFlags, colorEnabled: enabled };
    this.updatedEmailModel.featureFlags = { ...this.updatedEmailModel.featureFlags, colorEnabled: enabled };
    this.pref.updateEmailDetails(this.index, this.updatedEmailModel);
    this.isFeatureFlagChecked = true;
    this.pref.setStoreSettingPageModificationFlag(SettingsFlagType.ColorFlagEnabled,true);
  }

  public onModelNameChanged(enabled: boolean): void {
    this.email.featureFlags = { ...this.email.featureFlags, modelNameEnabled: enabled };
    this.updatedEmailModel.featureFlags = { ...this.updatedEmailModel.featureFlags, modelNameEnabled: enabled };
    this.pref.updateEmailDetails(this.index, this.updatedEmailModel);
    this.isFeatureFlagChecked = true;
    this.pref.setStoreSettingPageModificationFlag(SettingsFlagType.ModelNameFlagEnabled,true);
  }

  public onSizeChanged(enabled: boolean): void {
    this.email.featureFlags = { ...this.email.featureFlags, sizeEnabled: enabled };
    this.updatedEmailModel.featureFlags = { ...this.updatedEmailModel.featureFlags, sizeEnabled: enabled };
    this.pref.updateEmailDetails(this.index, this.updatedEmailModel);
    this.isFeatureFlagChecked = true;
    this.pref.setStoreSettingPageModificationFlag(SettingsFlagType.SizeFlagEnabled,true);
  }
  public onOpticianNameChanged(e: any): void {
    if (e.target.value != this.email.opticianName) {
      this.pref.setStoreSettingPageModificationFlag(SettingsFlagType.OpticianNameChanged,true);
    }
    else {
      this.pref.setStoreSettingPageModificationFlag(SettingsFlagType.OpticianNameChanged,false);
    }
    this.updatedEmailModel.opticianName = e.target.value;
    this.pref.updateEmailDetails(this.index, this.updatedEmailModel);
  }
  public notificationLanguageChange(change: MatSelectChange): void {
    if (change.value != this.email.notificationLanguage)
      this.isNotificationLanguageChanged = true;
    else
      this.isNotificationLanguageChanged = false;
    this.pref.setStoreSettingPageModificationFlag(SettingsFlagType.NotificationLanguageChanged,this.isNotificationLanguageChanged);
    this.updatedEmailModel.notificationLanguage = change.value;
    this.pref.updateEmailDetails(this.index, this.updatedEmailModel);
  }
  public async onInvitationTypeChanged(e: MatRadioChange) {

    if (!this.defaultInvitation) {
      await this.getNewInvitationMail();
      this.pref.isMailContentLoading$.next(false);     
      this.updatedEmailModel.customInvitation = this.invitationTemplate$.getValue(); 
    }
    this.updatedEmailModel.customInvitationEnabled = !this.defaultInvitation; 
    this.pref.updateEmailDetails(this.index, this.updatedEmailModel);
    this.isInvitataionMailSubmitDisabled = false;
    this.pref.setStoreSettingPageModificationFlag(SettingsFlagType.InvitationTypeChanged,true);
  }

  public onBannerImageChanged() {
    let dirtyBanner = this.email.customBannerEnabled !== this.customBannerEnabled;
    if (this.customBannerEnabled) {
      this.savableBannerOptions$.next(dirtyBanner && this.bannerExists$.getValue());

    } else {
      this.savableBannerOptions$.next(dirtyBanner);
    }
    this.updatedEmailModel.customBannerEnabled = (this.customBannerEnabled && this.bannerExists$.getValue());
    this.pref.updateEmailDetails(this.index, this.updatedEmailModel);
    this.pref.bannerSettingsModified$.next(this.savableBannerOptions$.getValue());

  }

  public onBannerImageExists(exists: boolean) {
    this.bannerExists$.next(exists);
    let dirtyBanner = this.email.customBannerEnabled !== this.customBannerEnabled;

    if (exists) {  
      this.savableBannerOptions$.next(dirtyBanner);
    } else if (!exists) {
      this.savableBannerOptions$.next(this.customBannerEnabled && this.bannerExists$.getValue());
    }
    this.pref.bannerSettingsModified$.next(this.savableBannerOptions$.getValue());
  }

  public onBannerImageSaved()
  {
    if (!this.customBannerEnabled) this.customBannerEnabled = true;
  }
  /* 
    Sets default banner option and saves ECPSettings
    To prevent deleting banner-image without switching banner options
  */
  public fallbackBannerImage() {
    if (this.email.customBannerEnabled) {
      this.customBannerEnabled = false;
      this.saveEmail();
    }
  }
  public onValuechanged(changed: boolean) {
    if (changed) {
      this.isInvitataionMailSubmitDisabled = false;
      this.pref.setStoreSettingPageModificationFlag(SettingsFlagType.InvitationMailContentChanged,true);
      if (!this.defaultInvitation) {
        const emailContentValue = this.invitationTemplate$.getValue();
        this.updatedEmailModel.customInvitation = emailContentValue;
        this.pref.updateEmailDetails(this.index, this.updatedEmailModel);
      }
    }
  }

  public async saveInvitationEmailTemplate() {

    if (!this.defaultInvitation) {
      const emailContent = this.invitationTemplate$.getValue();
      await this.pref.saveInvitationTemplate(emailContent);
    }
  }

  public saveEmail(e?: EmailSaveEvent) {
    if (e) {
      this._internalEmails[e.index] = e.email;
    }
    this.saveInvitationEmailTemplate();
    const validated = this.validateInternalEmails();

    const name = this.validateOpticianName();

    this.email.opticianName = name;
    this.email.emails = validated;
    this.email.invitationLanguage = this._selectedLanguage;
    this.email.notificationLanguage = this._selectednotificationLanguage;
    this.email.customInvitationEnabled = !this.defaultInvitation;
    this.email.customBannerEnabled = (this.customBannerEnabled && this.bannerExists$.getValue());
    // if(!this.defaultInvitation) this.email.customInvitation = this.invitationTemplate$.getValue();
    this.pref.resetStoreSettingPageModificationFlag();
    this.pref.setBannerSettingPageModificationFlag(false);
    this.onSave.emit(this.email);
  }

  private validateInternalEmails(): string[] {
    let validated: string[] = [];

    this._internalEmails.forEach(address => {
      const testResult: boolean = this.EMAIL_REGEX.test(address);

      if (testResult === true) {
        validated.push(address);
      }
    });

    return validated;
  }

  private validateOpticianName(): string {
    if (this.email.opticianName == this.opticianCustomName) {
      return this.email.opticianName;
    } else {
      return this.opticianCustomName;
    }
  }

  public async getNewInvitationMail() {
    this.templateLoading = true;
    this.pref.isMailContentLoading$.next(true);
    this.invitationTemplate$.next(null);
    const template = await this.pref.getNewInvitationMailTemplate(this.email.customInvitationEnabled && this.email.invitationLanguage === this._selectedLanguage ? this.email.opticianId : 'default', this._selectedLanguage);
    if (template) {
      let invitationMail = template;
      invitationMail.opticianId = this.email.opticianId;
      this.invitationTemplate$.next(invitationMail);
    } else {
      const e = new Error('Error while loading invitation-template! Please check language and template of optician.');
      this.appInsight.logException(e, { token: this.auth.getIdToken(), opticianId: this.email.opticianId, storeSettings: this.email });
      if (!environment.production) {
        throw e;
      } else {
        this.errorHandler.handleError(e);
      }
    }
    this.templateLoading = false;
  }

  public async languageChange() {
    if (!this.defaultInvitation) {
      if (this.invitationTemplate$.getValue() != null) {
        const result = await this.dialog.open(InvitationLanguageChangeDialogComponent, {
          width: '600px',
          autoFocus: false,
        })
          .afterClosed()
          .toPromise();
        if (result) {
          await this.getNewInvitationMail();
          this._invitationLanguagePreviousValue = this._selectedLanguage;    
          this.updatedEmailModel.customInvitation = this.invitationTemplate$.getValue();    
        }
        else
          this._selectedLanguage = this._invitationLanguagePreviousValue == null ? this.email.invitationLanguage : this._invitationLanguagePreviousValue;
        
      } else if (this.invitationTemplate$.getValue() == null) {
        await this.getNewInvitationMail();
        this.updatedEmailModel.customInvitation = this.invitationTemplate$.getValue(); 
      }  
    }
    this.isInvitationLanguageChanged = this._selectedLanguage != this.email.invitationLanguage;
    this.pref.setStoreSettingPageModificationFlag(SettingsFlagType.InvitationLanguageChanged, this.isInvitationLanguageChanged );
    if (this.isInvitationLanguageChanged || !this.isInvitataionMailSubmitDisabled) {
      this.updatedEmailModel.invitationLanguage = this._selectedLanguage;
      this.pref.updateEmailDetails(this.index, this.updatedEmailModel);
    }
    this.pref.isMailContentLoading$.next(false);
  }
    
  public openTemplateDialog(): void {
    const emailTemplate: InvitationMailTemplate = this.invitationTemplate$.getValue();
    this.dialog.open(InvitationMailPreviewDialogComponent, 
      { 
        autoFocus: false, 
        data: emailTemplate, 
        width: '90vw', 
        height: '80vh',
      });

  }

  public loadDefaultBanner() {
    this.defaultBanner$ = this.pref.getBannerImage("default").pipe(
      map(blob => blob != null ? blob : null)
    );
  }
}
