<div class="row">
    <!-- Loading Spinner -->
    <vcld-adm-spinner *ngIf="isLoading" [fullscreen]="true"></vcld-adm-spinner>

    <!-- alignment container -->
    <div class="col s10 offset-s1 container small-page-fix">
        <!--  title container -->
        <div class="col s12">
            <p class="adm-title">{{'pages.store-settings.title' | translate}}</p>
        </div>

        <!-- Close button -->
        <mat-icon class="dmt-close-icon" vcldAutomationId="adm_app_vis-adm-store-settings-page_close_btn" (click)="closeSettingsPage()">close</mat-icon>

        <!-- Avatar Upload Button -->
        <button *ngIf="canSee" mat-stroked-button color="primary" class="col s3 offset-s9" style="margin-top: 12px; margin-bottom: 12px;" (click)="demoAvatarUpload()" pk="admin_full_roleassignments">Upload Test-Avatars</button>

        <!-- Store Settings -->
        <div class="stores" *ngIf="(emailSettings$ | async)?.length > 1; else singleStore">
            <mat-accordion *ngFor="let email of (emailSettings$ | async);let i=index">
                <mat-expansion-panel class="col s12" (opened)="panelIsOpened(email.opticianId)" (closed)="panelIsClosed(email.opticianId)" [expanded]="getPanelState(email.opticianId)">
                    <mat-expansion-panel-header>
                        <mat-panel-title>
                            <span class="email-title cyan-text">{{email.opticianName}}</span>
                        </mat-panel-title>
                    </mat-expansion-panel-header>
                    <ng-template matExpansionPanelContent>
                        <vcld-adm-store-settings [index]="i" [isSingleOptician]="false" [isLoading]="isLoading" [email]="email"
                            (onSave)="saveEmail($event)">
                        </vcld-adm-store-settings>
                    </ng-template>
                </mat-expansion-panel>
                <hr style="margin-bottom: 0px;">
            </mat-accordion>
        </div>
        <ng-template #singleStore>
            <vcld-adm-store-settings *ngFor="let email of (emailSettings$ | async)" [isSingleOptician]="true"  [index]="0"
                [isLoading]="isLoading" [email]="email" (onSave)="saveEmail($event)">
            </vcld-adm-store-settings>
        </ng-template>
    </div>
</div>