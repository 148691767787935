import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatCheckboxChange } from '@angular/material/checkbox';

@Component({
    selector: 'vcld-adm-model-name-input',
    templateUrl: './model-name-input.component.html',
    styleUrls: ['./model-name-input.component.scss']
})

export class ModelNameInputComponent {


  @Input()
  public enabled: boolean = false;

  @Input()
  public disabled: boolean = false;

  @Output()
  public onChange: EventEmitter<boolean> = new EventEmitter<boolean>();

    public onCheckboxChanged(modelName: MatCheckboxChange): void {
      this.onChange.emit(modelName.checked);
  }

}