import { Component } from '@angular/core';
import { AdminAppEnvironment as environment } from 'visenvironment';
@Component({
    selector: 'vcld-pmcheck-page',
    templateUrl: './permission-check.page.component.html',
    styleUrls: []
})
export class PermissionCheckPageComponent {
    constructor() { 
        // Inject dependencies /set the default values for properties/ initialization code here.   
    }

    public get zeissLogo() {
        return environment.connectivity.zeiss_logo;
      }
}