import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'jobType' })
export class JobTypePipe implements PipeTransform {

    private readonly icons = ['touch_app', 'assistant']

    transform(value: number): string {
        if(value === 0)
            return 'assistant';
        return value === 1 ? 'touch_app' : 'INVALID';
    }
}
