<div style="position: absolute; background: #b4b4b4; height: 100%; width: 100%;">
    <div class="row" style="position: relative;">

        <div style="background: white; padding: 24px 18px; border-top: 4px solid #008bd0; margin-top: 15%;"
            class="col s4 offset-s4">
            <p style="font-size: 22pt; font-weight: 300;">{{'pages.logout.title' | translate}}</p>

            <p id="redirect-text" style="font-size: 12pt; margin-top: 12px;margin-bottom: 24px; ">
                {{'pages.logout.info' | translate}} 
                <a class="cyan-text" vcld style="cursor: pointer;" vcldAutomationId="adm_app_vcld_login_again" onclick="window.open(window.location.origin, '_self');">{{ "components.buttons.login" | translate }}   </a>               
            </p>           
          
            
            <img matListIcon [src]="zeissLogo" width="64px" alt="logo" style="margin-left: calc((100% - 64px) / 2);" />
        </div>
    </div>
</div>