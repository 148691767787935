// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

// tslint:disable: max-line-length

// DEV ENVIRONMENT FILE

export const AdminAppEnvironment = {
    production: false,
    version: "1.1.10+20",
    material_number: "000000-1012-172",
    authentication: {
        autoLogoutInMinutes: 30,
        signinProfile: "B2C_1A_ZeissIdNormalSignIn",
        signupProfile: "B2C_1A_ZeissIdNormalSignUp",
        clientId: "514e4b9c-cead-4c3d-9269-2fc58071d0fa",
        disableAtHashCheck: true,
        clearHashAfterLogin: true,
        redirectUri: "https://vcld-admin-portal-dev.azurewebsites.net",
        requestAccessToken: false,
        responseType: "code",
        scope: "openid profile offline_access",
        showDebugInformation: true,
        skipIssuerCheck: true,
        strictDiscoveryDocumentValidation: false,
        timeoutFactor: 0.75,
        overrideDiscoveryDocumentUri:
            "https://id-ip-stage.zeiss.com/CD/OAuth/OpenidConfiguration?p=B2C_1A_ZeissIdNormalSignIn",
        overrideDiscoveryDocumentUriSignUp:
            "https://id-ip-stage.zeiss.com/CD/OAuth/OpenidConfiguration?p=B2C_1A_ZeissIdNormalSignUp",
    },
    monitoring: {
        appInsightsConnectionString: "InstrumentationKey=a87e03cd-8036-ca8f-8d5d-d9b05910ba01;EndpointSuffix=applicationinsights.azure.cn;IngestionEndpoint=https://chinaeast2-0.in.applicationinsights.azure.cn/;AADAudience=https://monitor.azure.cn/"
    },
    connectivity: {
        frontdoorApiBaseUrl: null,
        appConfig:
            "https://vcldcommonstaticfiles.blob.core.chinacloudapi.cn/configuration/china-dev/admin-config.json",
        translations:
            "https://vcldcommonstaticfiles.blob.core.chinacloudapi.cn/translations/dev/admin/",
        translation_registry:
            "https://vcldcommonstaticfiles.blob.core.chinacloudapi.cn/translations/dev/registry/admin.json",
        esbBaseUrl: "https://esb-dev.zeiss.com.cn/public/api/vis",
        fpsEndpoint: "https://esb-dev.zeiss.com.cn/public/api/vis/vcld/FPS",
        resendInvitationEndpoint:
            "https://esb-dev.zeiss.com.cn/public/api/vis/vcld-admin/resendinvitation",
        demo_avatar:
            "https://esb-dev.zeiss.com.cn/public/api/vis/vcld-admin/demo-avatar",
        zeiss_logo:
            "https://zeissid-cdn-stage.azureedge.net/src/images/zeiss-logo.svg",
        ecp_settings:
            "https://esb-dev.zeiss.com.cn/public/api/vis/vcld-admin/ecp-settings",
        avatarSessionEndpoint:
            "https://esb-dev.zeiss.com.cn/public/api/vis/vcld-admin/AvatarCreationSessions/{opticianId}",
        avatarsCountEndpoint:
            "https://esb-dev.zeiss.com.cn/public/api/vis/vcld-admin/AvatarSessions/{opticianId}",
        email_endpoint:
            "https://esb-dev.zeiss.com.cn/public/api/vis/vcld/contact-mail",
        deleteAvatarSessionEndpoint:
            "https://esb-dev.zeiss.com.cn/public/api/vis/vcld-admin/AvatarCreationSessions",
        securityEndpoint:
            "https://esb-dev.zeiss.com.cn/public/api/vis/vcld-admin/security",
        centration:
            "https://esb-dev.zeiss.com.cn/public/api/vis/vcld-admin/centration/centration-jobs/",
        ecp_onboarding:
            "https://esb-dev.zeiss.com.cn/public/api/vis/vcld-admin/ecp-onboarding",
        coBrandingLogo:
            "https://esb-dev.zeiss.com.cn/public/api/vis/vcld-admin/ecp-branding",
        invitationTemplates:
            "https://esb-dev.zeiss.com.cn/public/api/vis/vcld-admin/invitation-template/{opticianId}",
        invitationComponents:
            "https://esb-dev.zeiss.com.cn/public/api/vis/vcld-admin/invitation-component",
        previewInvitationTemplate:
            "https://esb-dev.zeiss.com.cn/public/api/vis/vcld-admin/preview-invitation-template/{opticianId}",
        gfdbCDNConnectionEndpoint:"https://gfdb-stage.azureedge.net/stagecdnvisualizationassetstore",
        getStores: 'https://esb-dev.zeiss.com.cn/public/api/vis/vcld-admin/opticians',
        customBanner:
            "https://esb-dev.zeiss.com.cn/public/api/vis/vcld-admin/custom-img/{opticianId}",
        getDefaultBanner:
            "https://esb-dev.zeiss.com.cn/public/api/vis/vcld-admin/default-img",
        marketing: {
            getStores:
                "https://esb-dev.zeiss.com.cn/public/api/vis/vcld-admin/marketing/opticians",
            createCampaign:
                "https://esb-dev.zeiss.com.cn/public/api/vis/vcld-admin/marketing/campaign",
            getCustomers:
                "https://esb-dev.zeiss.com.cn/public/api/vis/vcld-admin/marketing/customers",
            selectCustomers:
                "https://esb-dev.zeiss.com.cn/public/api/vis/vcld-admin/marketing/select-customers",
            getFrames:
                "https://esb-dev.zeiss.com.cn/public/api/vis/vcld-admin/marketing/framecollections",
            selectFrames:
                "https://esb-dev.zeiss.com.cn/public/api/vis/vcld-admin/marketing/filter",
            getEmailTemplates:
                "https://esb-dev.zeiss.com.cn/public/api/vis/vcld-admin/marketing/template",
            getEmailComponents:
                "https://esb-dev.zeiss.com.cn/public/api/vis/vcld-admin/marketing/component",
            createOrUpdateTemplate:
                "https://esb-dev.zeiss.com.cn/public/api/vis/vcld-admin/marketing/template",
            previewTemplate:
                "https://esb-dev.zeiss.com.cn/public/api/vis/vcld-admin/marketing/previewtemplate",
            getAvatarThumbnail:
                "https://esb-dev.zeiss.com.cn/public/api/vis/vcld-admin/marketing/avatars/{sessionId}/thumbnail",
            getFrameThumbnail:
                "https://esb-dev.zeiss.com.cn/public/api/vis/vcld-admin/marketing/frames/{frameId}/thumbnail",
            submitCampaign:
                "https://esb-dev.zeiss.com.cn/public/api/vis/vcld-admin/marketing/sendcampaign",
            setRecommendationSettings:
                "https://esb-dev.zeiss.com.cn/public/api/vis/vcld-admin/marketing/campaign",
            setSelectedRecommendation:
                "https://esb-dev.zeiss.com.cn/public/api/vis/vcld-admin/marketing/recommendation",
            getDMTLogo:
                "https://esb-dev.zeiss.com.cn/public/api/vis/vcld-admin/marketing/{opticianId}/ecplogo",
            settings:
                "https://esb-dev.zeiss.com.cn/public/api/vis/vcld-admin/marketing/settings/{opticianId}",
            recommendationProgress:
                "https://esb-dev.zeiss.com.cn/public/api/vis/vcld-admin/marketing/sessionStatus/{campaignId}",
            customDMTBanner:
                "https://esb-dev.zeiss.com.cn/public/api/vis/vcld-admin/marketing/banner-img/{templateId}",
            getFrameThumbnailCDN:
                "https://gfdb-stage.azureedge.net/stagecdnvisualizationassetstore/{frameId}/INSTORE/THUMBNAIL.PNG"
        },
    },
    contact: {
        zeiss_support: "jochen.uebelhoer@zeiss.com",
        form_stack: "https://myzeiss.formstack.com/forms/servicerequest_admin",
    },
};

export const ConsumerAppEnvironment = {
    production: false,
    version: "1.1.10+20",
    material_number: "000000-1012-171",
    authentication: {
        autoLogoutInMinutes: 30,
        signinProfile: "B2C_1A_ZeissIdCNTestSignIn",
        signupProfile: "B2C_1A_ZeissIdCNTestSignUp",
        clientId: "a149d90f-210b-4559-addb-8c797e418ee8",
        disableAtHashCheck: true,
        clearHashAfterLogin: true,
        requestAccessToken: false,
        responseType: "code",
        scope: "openid profile offline_access",
        dummyClientSecret: "Z7X4KGb7.b-Ff~9Qxc9c-Viz-dvKw5W58~",
        issuer: "https://accountsstage.b2clogin.cn/f0af5415-725c-4a40-b2e1-cf5ad573c4ee/v2.0/",
        showDebugInformation: true,
        skipIssuerCheck: true,
        strictDiscoveryDocumentValidation: false,
        timeoutFactor: 0.75,
        overrideDiscoveryDocumentUri:
            "https://id-ip-stage.zeiss.com.cn/OAuth/OpenidConfiguration?p=B2C_1A_ZeissIdCNTestSignIn",
        overrideDiscoveryDocumentUriSignUp:
            "https://id-ip-stage.zeiss.com.cn/OAuth/OpenidConfiguration?p=B2C_1A_ZeissIdCNTestSignUp",
    },

    monitoring: {
        appInsightsConnectionString: "InstrumentationKey=a87e03cd-8036-ca8f-8d5d-d9b05910ba01;EndpointSuffix=applicationinsights.azure.cn;IngestionEndpoint=https://chinaeast2-0.in.applicationinsights.azure.cn/;AADAudience=https://monitor.azure.cn/"
    },

    connectivity: {
        frontdoorApiBaseUrl: null,
        introImages:
            "https://vcldcommonstaticfiles.blob.core.chinacloudapi.cn/assets/dev/intro",
        appConfig:
            "https://vcldcommonstaticfiles.blob.core.chinacloudapi.cn/configuration/china-dev/consumer-config.json",
        appLinksConfig:
            "https://vcldcommonstaticfiles.blob.core.chinacloudapi.cn/translations/dev/links/rights-links.json",
        translations:
            "https://vcldcommonstaticfiles.blob.core.chinacloudapi.cn/translations/dev/consumer/",
        translation_registry:
            "https://vcldcommonstaticfiles.blob.core.chinacloudapi.cn/translations/dev/registry/consumer.json",
        frameVariants:
            "https://esb-dev.zeiss.com.cn/public/api/vis/vcld/frame",
        esbBaseUrl:
            "https://esb-dev.zeiss.com.cn/public/api/vis/vcld",
        dmt_link_used:
            "https://esb-dev.zeiss.com.cn/public/api/vis/vcld/mailaccess",
        email_endpoint:
            "https://esb-dev.zeiss.com.cn/public/api/vis/vcld/contact-mail",
        frame_selection_endpoint:
            "https://esb-dev.zeiss.com.cn/public/api/vis/vcld/select-frame",
        avatarSessionEndpoint:
            "https://esb-dev.zeiss.com.cn/public/api/vis/vcld/sessions",
        deleteAvatarSessionEndpoint:
            "https://esb-dev.zeiss.com.cn/public/api/vis/vcld/{id}/avatar",
        avatarSessionBinary:
            "https://esb-dev.zeiss.com.cn/public/api/vis/vcld/{id}/binary",
        framesEndpoint:
            "https://esb-dev.zeiss.com.cn/public/api/vis/vcld/frames",
        favoriteEndpoint:
            "https://esb-dev.zeiss.com.cn/public/api/vis/vcld/favorites",
        generatePublicDomain:
            "https://esb-dev.zeiss.com.cn/public/api/vis/vcld/share/{sessionId}",
        getPublicFavorites:
            "https://esb-dev.zeiss.com.cn/public/api/vis/vcld/share/favorites/{sessionId}?token={token}",
        getRatings:
            "https://esb-dev.zeiss.com.cn/public/api/vis/vcld/ratings/{sessionId}",
        getSurveys:
            "https://esb-dev.zeiss.com.cn/public/api/vis/vcld/surveys/{frameId}?sessionId={sessionId}",
        postSurveys:
            "https://esb-dev.zeiss.com.cn/public/api/vis/vcld/surveys?token={token}",
        fetchImageForSocialShare:
            "https://esb-dev.zeiss.com.cn/public/api/vis/vcld/share/image/{frameId}.jpg?token={token}&sessionId={sessionId}",
        socialMetaTagEndpoint:
            "https://vcld-api-dev.azurewebsites.net/api/open-graph-tags?token={accessToken}&sessionId={sessionId}&frameId={frameId}",
        fpsEndpoint:
            "https://esb-dev.zeiss.com.cn/public/api/vis/vcld/FPS",
        recommendationsEndpoint:
            "https://esb-dev.zeiss.com.cn/public/api/vis/vcld/recommendations",
        lastAccessEndpoint:
            "https://esb-dev.zeiss.com.cn/public/api/vis/vcld/lastAccess",
        coBrandingLogo:
            "https://esb-dev.zeiss.com.cn/public/api/vis/vcld/branding/logo",
        ecpSettings:
            "https://esb-dev.zeiss.com.cn/public/api/vis/vcld/branding/features/{opticianId}",
        removeDMTRecommendation:
            "https://esb-dev.zeiss.com.cn/public/api/vis/vcld/recommendations",
        unsubscribeEndpoint:
            "https://esb-dev.zeiss.com.cn/public/api/vis/vcld/marketing/unsubscribe",
        statisticsEndpoint:
            "https://esb-dev.zeiss.com.cn/public/api/vis/vcld/statistics/interaction",
        tintsAndCoatingEndpoint:
            "https://esb-dev.zeiss.com.cn/public/api/vis/vcld/consumer/tints-coating",
        updateVtoSessionEndpoint: "https://esb-dev.zeiss.com.cn/public/api/vis/vcld/session/{sessionId}/frames",
        gfdbCDNConnectionEndpoint: "https://gfdb-stage.azureedge.net/stagecdnvisualizationassetstore",

        appIds: {
            facebook: {
                prod: "2547357745318966",
                dev: "438678780138770",
            },
        },

        socialMedia: {
            facebook: "https://www.facebook.com/sharer/sharer.php?u={url}",
        },
    },

    contact: {
        zeiss_support: "jochen.uebelhoer@zeiss.com",
        form_stack:
            "https://myzeiss.formstack.com/forms/servicerequest_consumer_webapp",
    },

    rights: {
        terms_and_conditions:
            "https://id-stage.zeiss.com/Documents/UserAgreement/141",
        impresum_de_DE:
            "https://www.zeiss.de/vision-care/imprint/impressum.html",
        impresum_en_US:
            "https://www.zeiss.com/vision-care/int/imprint/impressum.html",
        impresum_it_IT:
            "https://www.zeiss.it/vision-care/linee-guida/editore.html",
        impresum_nl_NL:
            "https://www.zeiss.nl/vision-care/juridische-informatie/bedrijfsinformatie.html",
        impresum_nl_BE:
            "https://www.zeiss.be/vision-care/nl/juridische-informatie/publisher.html",
        impresum_fr_FR:
            "https://www.zeiss.fr/vision-care/informations-legales/editeur.html",
        impresum_es_ES:
            "https://www.zeiss.es/vision-care/nota-legal/autor.html",
        impresum_zh_CN:
            "https://www.zeiss.com.cn/corporate/en/legal-information/publisher.html",
        impresum_ja_JP:
            "https://www.zeiss.co.jp/vision-care/legal-information/publisher.html",
        impresum_pt_PT:
            "https://www.zeiss.pt/vision-care/informacao-legal/editor.html",
        impresum_da_DK: 
            "https://www.zeiss.dk/vision-care/rettigheder/selskabsinformation.html",
        impresum_sv_SE: 
            "https://www.zeiss.se/vision-care/juridisk-information/foeretagsinformation.html",
        impresum_cs_CZ: 
            "https://www.zeiss.cz/vision-care/imprint/informace-o-spolecnosti.html",
        impresum_sk_SK: 
            "https://www.zeiss.sk/vision-care/imprint/publisher.html",
        impresum_nn_NO: 
            "https://www.zeiss.no/vision-care/rettigheter/informasjon-om-selskapet.html",
        impresum_fi_FI: 
            "https://www.zeiss.fi/vision-care/lakiasiat/tietoja-yrityksestae.html",
        impresum:
            "https://www.zeiss.com/vision-care/int/imprint/impressum.html",
        data_protection_en_US: "https://www.zeiss.com/data-protection",
        data_protection_de_DE: "https://www.zeiss.de/datenschutz/home.html",
        data_protection_nl_NL:
            "https://www.zeiss.nl/gegevensbescherming/home.html",
        data_protection_nl_BE:
            "https://www.zeiss.be/gegevensbescherming/home.html",
        data_protection_fr_FR:
            "https://www.zeiss.es/proteccion-de-datos/home.html",
        data_protection_es_ES:
            "https://www.zeiss.ch/protection-des-donnees/home.html",
        data_protection_zh_CN:
            "https://www.zeiss.com.cn/data-protection/en/home.html?vaURL=www.zeiss.com.cn/en/data-protection",
        data_protection_it_IT: "https://www.zeiss.it/protezione-dei-dati",
        data_protection_ja_JP: "https://www.zeiss.co.jp/data-protection",
        data_protection_pt_PT: "https://www.zeiss.pt/protecao-de-dados/home.html",
        data_protection_da_DK: "https://www.zeiss.dk/datasikkerhed/databeskyttelse.html?vaURL=www.zeiss.dk/datasikkerhed",
        data_protection_sv_SE: "https://www.zeiss.se/skydd-av-personuppgifter/dataskydd.html?vaURL=www.zeiss.se/skydd-av-personuppgifter",
        data_protection_cs_CZ: "https://www.zeiss.cz/ochrana-udaju/home.html?vaURL=www.zeiss.cz/ochrana-udaju",
        data_protection_sk_SK: "https://www.zeiss.sk/ochrana-osobnych-udajov/ochrana-udajov.html",
        data_protection_nn_NO: "hhttps://www.zeiss.no/databeskyttelse/databeskyttelse.html",
        data_protection_fi_FI: "https://www.zeiss.fi/tietosuoja",
        withdrawal_de_DE:
            "https://www.zeiss.de/datenschutz/home/anfrage-zum-datenschutz.html",
        withdrawal_en_US:
            "https://www.zeiss.com/data-protection/int/home/anfrage-zum-datenschutz.html",
        withdrawal_ja_JP: "https://www.zeiss.com/data-protection-request",
    },

    content: {
        logo: "https://zeissid-cdn-stage.azureedge.net/src/images/zeiss-logo.svg",
    },

    survey: {
        timeInMinutes: 5,
        links: {
            de: "https://www.surveymonkey.de/r/8N7D7GJ?avatar1=[avatar1_value]&avatar2=[avatar2_value]&avatar3=[avatar3_value]&avatar4=[avatar4_value]&avatar5=[avatar5_value]&deviceid=[deviceid_value]&appversion=[appversion_value]",
            en: "https://www.surveymonkey.de/r/NS6MK8H?avatar1=[avatar1_value]&avatar2=[avatar2_value]&avatar3=[avatar3_value]&avatar4=[avatar4_value]&avatar5=[avatar5_value]&deviceid=[deviceid_value]&appversion=[appversion_value]",
        },
    },
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
