import { Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'vcld-adm-spinner',
    templateUrl: './loading-spinner.component.html',
    styleUrls: ['./loading-spinner.component.scss']
})
export class LoadingSpinnerComponent implements OnInit {

    @Input()
    public fullscreen: boolean = true;

    @Input()
    public text: string;

    @Input()
    public displayText: boolean = false;

    @Input()
    customerCount: number = 0;

    @Input()
    processed: number = 0;

    @Input()
    public overlayPosition:string ="absolute";

    /** Indicates if the 'text' is shown */
    public display: boolean = false;

    /** Time in seconds till the 'wait' message is shown */
    private readonly displayTimeForMessage: number = 10;

    public get processText() {
        return `${this.processed}/${this.customerCount}`;
    }

    constructor() { 
        // Inject dependencies /set the default values for properties/ initialization code here.   
    }

    ngOnInit() {
        setTimeout(() => {
            if (!this.text) return;
            this.display = true;
        }, this.displayTimeForMessage * 1000);
    }
}
