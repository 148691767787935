import { AfterViewInit, Component, ElementRef, ErrorHandler, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { map, tap } from 'rxjs/operators';
import { PreferencesService } from '../../services/preferences.service';
import { FileUploadObjectModel,UploadFileType} from '../../models/store-setting.object.model';
import { SettingsFlagType } from '../../models/store-setting.submitflag.model';

@Component({
    selector: 'vcld-adm-logo-input',
    templateUrl: './logo-input.component.html',
    styleUrls: ['./logo-input.component.scss']
})
export class ECPLogoInputComponent implements OnInit, OnChanges {

    public loading: boolean = true;

    public changed: boolean = false;

    public ecpLogo$;

    public logoUploadObject:FileUploadObjectModel;

    public logoUpdated:boolean=false;

    public get isDisabled(): boolean {
        if (this.changed && !this.loading) {
            return this.fileInput == null;
        }
        if (this.loading) {
            return true;
        }
        return true;
    }

    @Input()
    public opticianId: string;

    @Input()
    public origin: "dmt-settings" | "store-settings";

    @Input()
    public canEdit: boolean = true;

    @Output()
    public change: EventEmitter<any> = new EventEmitter();

    public fileInput:FileList;

    @Output()
    public fileInputChange: EventEmitter<File> = new EventEmitter();

    @Output()
    public logoSaved: EventEmitter<boolean> = new EventEmitter();

    constructor(
        private translate: TranslateService,
        private pref: PreferencesService,
        private errorHandler: ErrorHandler,
    ) { }


    public ngOnInit() {
        this.changed = false;
    }

    public ngOnChanges(changes: SimpleChanges): void {
        const change = changes['opticianId']?.currentValue;
        if(change !== null && change !== undefined) {
            this.loadECPLogo();
        }
    }


    public fileInputChanged(eventData:FileList) {
        this.fileInput=eventData;
        
        if (this.fileInput == null) return;

        this.changed = true;  
        this.logoUpdated=false; 
        const file = this.fileInput[0];
        this.fileInputChange.emit(file);    
        if(this.origin=="store-settings")
        {       
        this.pref.setStoreSettingPageModificationFlag(SettingsFlagType.LogoUpdated,true);
        }

    }

    public saveLogo(e: Event): void {
        this.pref.setStoreSettingPageModificationFlag(SettingsFlagType.LogoUpdated,false);
        this.loading = true;
        (e.target as HTMLButtonElement).disabled = true;
        const file = this.fileInput[0];
        this.logoUpdated=true;
        this.pref.setECPLogo(file, this.opticianId, this.origin).toPromise()
            .then(() => {
                this.fileInput = null;
                this.loadECPLogo();
                this.logoSaved.emit(true);
            })
            .catch((err) => { 
                this.errorHandler.handleError(err); 
                this.loading = false; 
            });   
    }

    public removeLogo(e: Event) {
        this.loading = true; 
        (e.target as HTMLButtonElement).disabled = true;
        this.pref.removeECPLogo(this.opticianId, this.origin).toPromise()
            .then(_ => {
                this.loadECPLogo();
                this.change.emit();
            })
            .catch(e => {
                this.errorHandler.handleError(e);
                this.loading = false;
            })
    }

    private loadECPLogo() {
        this.ecpLogo$ = this.pref.getECPLogo(this.opticianId, this.origin).pipe(
            tap(blob => {
                this.loading = false;
            }),
            map(blob => blob != null ? blob : null));
    }
}
