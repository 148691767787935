<div class="row">
    <!-- Close button -->
    <mat-icon class="dmt-close-icon" vcldAutomationId="adm_app_vis-adm-session-page_close_btn" routerLink="/avatar-overview">close</mat-icon>

    <div class="col s10 offset-s1 small-page-fix" style="margin-top: 64px; position: relative;">
        <div *ngIf="isLoading" class="table-overlay">
            <mat-spinner mode="indeterminate"></mat-spinner>
        </div>

        <div class="col s12">
            <p class="adm-title">
                {{ "pages.avatar-sessions.title" | translate }}
            </p>
        </div>

        <div class="col s12">
            <div class="col s12">
                <p class="subheading" *ngIf="isCameronSource">
                    {{ "pages.avatar-sessions-overview.acs-avatar-cameron" | translate }}
                </p>
    
                <p class="subheading" *ngIf="!isCameronSource">
                    {{ "pages.avatar-sessions-overview.acs-avatar-visufit" | translate }}
                </p>
            </div>
        </div>

        <div class="col s12 filter-export">
            <mat-form-field class="filter-drop" *ngIf="multipleStores$ | async">
                <mat-label>{{'pages.dmt-overview.selected-store' | translate}}</mat-label>
                <mat-select [value]="selectedStore$ | async" vcldAutomationId = "adm_app_vis-adm-session-page_select-store_ddl" (valueChange)="changeStore($event)">
                    <mat-option [value]="avSession.allStores"> {{ "pages.avatar-sessions.all_stores" | translate }}</mat-option>
                    <mat-option *ngFor="let store of stores$ | async" [value]="store" vcldAutomationId = "adm_app_vis-adm-session-page_select-store_option">{{store.customName}}</mat-option>
                </mat-select>
            </mat-form-field>

            <button mat-flat-button color="primary" vcldAutomationId = "adm_app_vis-adm-session-page_export_btn" class="export-btn" download="Avatars accessed.csv" (click)="downloadAcs()">
                {{ "pages.avatar-sessions.export" | translate}}: {{ "components.buttons.acsAccessed" | translate }}
            </button>
        </div>

        <table class="col s12" mat-table [dataSource]="dataSrc" matSort matSortDirection="asc">
            <!-- Definition for "Id" -->
            <ng-container matColumnDef="id">
                <th mat-header-cell *matHeaderCellDef mat-sort-header style="width: 30%" vcldAutomationId="adm_app_vis-adm-session-page_avatar-id_column">
                    {{ "pages.avatar-sessions.avatar_id" | translate }}
                </th>
                <td mat-cell *matCellDef="let row" style="width: 30%">
                    {{ row.id }}
                </td>
            </ng-container>

            <!-- Definition for "UploadedAt" -->
            <ng-container matColumnDef="uploadedAt">
                <th mat-header-cell *matHeaderCellDef mat-sort-header style="width: 30%" vcldAutomationId="adm_app_vis-adm-session-page_uploaded-on_column">
                    {{ "pages.avatar-sessions.uploaded_on" | translate }}
                </th>
                <td mat-cell *matCellDef="let row" style="width: 30%">
                    {{ row.uploadedAt | date }}
                </td>
            </ng-container>

            <!-- Definition for "ConsumerId" -->
            <ng-container matColumnDef="fullname">
                <th mat-header-cell *matHeaderCellDef style="width: 30%">
                    {{ "pages.avatar-sessions.name" | translate }}
                </th>
                <td mat-cell *matCellDef="let row" style="width: 30%">
                    {{ row.fullname }}
                </td>
            </ng-container>

            <!-- Action Definition -->
            <ng-container matColumnDef="action">
                <th mat-header-cell *matHeaderCellDef style="width: 10%">
                    {{ "pages.avatar-sessions.delete" | translate }}
                </th>
                <td mat-cell *matCellDef="let row" style="width: 10%">
                    <button pk="admin_delete_avatarsessionlist" vcldAutomationId= "adm_app_vis-adm-session-page_delete_btn" (click)="onDelete(row)" mat-icon-button
                        aria-label="delete" class="text1">
                        <mat-icon>delete</mat-icon>
                    </button>
                </td>
            </ng-container>

            <!-- DataSet itself -->
            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
        </table>

        <!-- Paginator -->
        <mat-paginator class="col s12" [length]="dataSrcTotal" [pageSizeOptions]="[10, 15, 25, 50]"></mat-paginator>
    </div>
</div>